@import '../../../../../../styles/variables';

.add-park-modal {
    .modal-body {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0.5rem;
    }

    .custom-modal-dialog {
        max-width: 95vw;
        width: 95vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .modal-header-override {
        position: relative;
        padding-top: 1rem;
    
        &::before {
            border-top-left-radius: 0.3rem;
            border-top-right-radius: 0.3rem;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 0.5rem;
            width: 100%;
            background-color: $primary;
            border-bottom: 1px solid #333;
        }
    }

    .modal-content {
        margin-top: 1rem;
        height: 95vh;
        overflow: hidden;
    }

    .form-override {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .turbine-add-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 10px;
      
        .add-turbine-icon {
            cursor: pointer;
            color: $tertiary;
            margin-top: 10px;
        }
    }
      

    .remove-turbine-icon {
        cursor: pointer;
        color: red;
        background-color: transparent;
    }

    .turbine-table, .add-turbine-form, .dummy-form {
        flex: 1;
        box-sizing: border-box; 
    }
    
    .turbine-table {
        margin-right: 1rem; 
    }
        
    .add-turbine-form, .dummy-form {
        margin-left: 1rem; 
    }
}

.tertiary-border {
    border-bottom: 5px solid $tertiary;
    width: 100%;
    margin-top: 3px;
}