@import '../../../styles/variables';

.windparken-container {
  margin-top: 3rem;
  overflow-x: hidden;
}

.float-button {
  position: fixed;
  background-color: $tertiary;
  top: 5.5rem;
  right: 1rem;
  z-index: 1;
}

.windparken-table {
  margin-top: 3.5rem;
}
