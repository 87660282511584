@import '../../../../../styles/variables';

.windparken-table-container {
  margin: 1rem;
  overflow-x: hidden;
}

.windparken-table {
  height: 100%;

  .tbody-container {
    display: block;
    background-color: white;
    opacity: 0.8;
    overflow-y: hidden;
    height: 72vh;
    width: 100%;
  }

  td {
    cursor: pointer;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .narrow-table-el {
    width: 4rem;
  }

  .mid-table-el {
    width: 6rem;
  }

  .large-table-el {
    width: 14rem;
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: default;
  }

  .pagination button {
    margin: 0 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    background: $primary;
    color: white;
    cursor: pointer;
    border-color: $tertiary;
  }

  .pagination button.active {
    background: $secondary;
    color: white;
  }
}
