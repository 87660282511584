@use 'sass:color';
@import '../../../styles/variables';


.dashboard-icon {
    position: absolute;
    margin-top: 1rem;
    margin-left: 1rem;

    .dashboard-icon-btn {
        background-color: $secondary;
        border-color: $secondary;
        color: white;
        font-size: 1.5rem;
        height: 40px;
        width: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }

        &:hover,
        &:active {
            background-color: color.adjust($secondary, $lightness: -10%);
            border-color: color.adjust($secondary, $lightness: -10%);
            color: color.adjust(white, $lightness: -10%);
        }
    }
}

