@import '../../../../../../styles/variables';

.park-details-modal {
  .modal-body {
    position: relative;
  }

  .section-container {
    position: relative;
    margin-bottom: 1rem;
  }

  .toggle-button {
    position: absolute;
    right: 0;
    top: 0;
  }

  .placeholder-card {
    width: 100%;
    align-items: center;
  }

  .custom-modal-dialog {
    max-width: 95vw !important;
    width: 95vw !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .modal-content {
    margin-top: 2rem;
    height: 90vh;
    overflow: hidden;
  }

  .modal-header-override {
    position: relative;
    padding-top: 1rem;

    &::before {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 0.5rem;
      width: 100%;
      background-color: $primary;
      border-bottom: 1px solid #333;
    }
  }

  .table-scrollable {
    height: 30vh;
    overflow-y: auto;
    display: block;
    width: 100%;

    thead {
      position: relative;

      th {
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
        padding-bottom: 5px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          border-top: 5px solid $tertiary;
          z-index: 10;
        }
      }
    }
  }
}
