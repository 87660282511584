.sign-out-icon {
  font-size: 1.5rem;
  height: 40px;
  width: 40px;
  cursor: pointer;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  color: white;
}
