@import '../../../styles/variables';

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 8rem 1rem 2rem;
  height: 100vh;
  background-color: rgb(255 255 255 / 70%);
}

.planning-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100vh; 
  overflow-y: hidden; 

  .toolbar-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .timeline-wrapper {
    flex: 1; 
    position: relative;
    overflow: hidden hidden;
    margin-bottom: 2rem;
    margin-right: 1rem;
  }
}



.float-button {
  position: fixed;
  background-color: $tertiary;
  top: 6rem;
  right: 1rem;
  z-index: 1;
}

.btn.no-hover:hover,
.btn.no-hover:active,
.btn.no-hover:focus {
  background-color: inherit !important;
  border-color: inherit !important;
  box-shadow: none !important;
  color: inherit !important;
}
