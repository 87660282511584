// Huisstijl
@use "sass:color";

$primary: #009ad8;
$primary-light: #a028a8;
$secondary: #ef7d00;
$tertiary: #75bf3e;
$danger: #dc3545;
$warning: #E4A11B;

