.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-side-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.right-side-buttons > *:not(:last-child) {
  margin-right: 10px;
}

.turbine-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remove-turbine-icon {
  cursor: pointer;
  color: red;
}
