.windpark-select {
  margin-left: 1rem;
  width: 200px;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.in-modal {
  margin-left: 0 !important;
}
