.narrow-table-el {
  width: 4rem;
}

.mid-table-el {
  width: 10rem;
}

.large-table-el {
  width: 20rem;
}

.small-font {
  font-size: 0.6rem;
  text-align: center;
}

.medium-font {
  font-size: 0.8rem;
  text-align: center;
}

.large-font {
  font-size: 1rem;
  text-align: center;
}
