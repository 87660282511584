@import '../../../../styles/variables';

.timeline-legend {
  background-color: rgb(255 255 255 / 85%);
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.8rem;

  h5 {
    margin-bottom: 0.5rem;
  }

  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    .legend-color {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }

    .external-item {
      background-color: $primary;
      color: white !important;

      &.sweep { background-color: $primary; }
      &.lofar { background-color: $primary-light }
    }

    .internal-override {
      background-color: $secondary;
    }

    .internal-manual {
      background-color: $tertiary;
    }

    .cbc-planning {
      background-color: rgb(0 129 86);
    }
  }
}
