@import '../../styles/variables';

.background-image {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  background-color: rgb(255 255 255 / 70%);
  padding: 2rem;
  border-radius: 5px;
  text-align: center;
}

.pure-energie-logo {
  width: 200px;
  margin-bottom: 1rem;
}

.login-button {
  margin-top: 1rem;
}
