/* stylelint-disable scss/at-import-no-partial-leading-underscore */
/* stylelint-disable no-invalid-position-at-import-rule */
// Importeer Bootstrap-variabelen en -functies
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import './variables';

// Overschrijven Bootstrap-variabelen
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'tertiary': $tertiary,
  'primary-light': $primary-light,
  'success': #198754,
  'info': #0dcaf0,
  'warning': #ffc107,
  'danger': #dc3545,
  'light': #f8f9fa,
  'dark': #212529,
);
@import '~bootstrap/scss/bootstrap';

.btn-tertiary {
  @include button-variant(
    map-get($theme-colors, 'tertiary'),
    map-get($theme-colors, 'tertiary'),
    map-get($theme-colors, 'tertiary')
  );
}

@import './custom-button-styles';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#root {
  overflow-x:hidden;
}

body {
  background-image: url('../assets/background.jpg');
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

a,
button,
[role='button'],
[type='button'],
[type='submit'],
[type='reset'],
[contenteditable] {
  &:hover,
  &:active {
    filter: brightness(90%);
  }
}

.datepicker {
  display: block;
}
