@import '../../../styles/variables';

.custom-input-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  position: relative;
  min-height: 40px;

  .tooltip-error {
    position: absolute;
    bottom: -20px;
    right: 0;
    background-color: rgb(255 0 0 / 70%);
    color: white;
    padding: 5px;
    border-radius: 5px;
    display: none;
    z-index: 10;
  }

  .input-width {
    width: 250px;
  }

  .date-width {
    width: 250px;
  }

  &:hover .tooltip-error {
    display: block;
  }
}
