@import '../../../../../../../styles/variables';

.form-container {
  margin-left: 1rem;
  width: 50%;

  .form-header {
    margin-left: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .add-turbine-form-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    margin-left: 1rem;
    padding-bottom: 1rem;

    .add-turbine-form {
      padding-top: 1rem;
    }

    .form-buttons {
      margin-top: 1rem;
      margin-right: 1rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .dummy-turbine-button {
      margin-left: 1rem;
    }

    .form-buttons .form-reset-btn,
    .form-buttons .form-submit-btn {
      margin-left: 0.5rem;
    }

    .label-date {
      display: flex;
    }
  }
}
