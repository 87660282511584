@import '../../../../../styles/variables';

.modal-header-cbc {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  
  .modal-title {
    color: #495057; 
  }

  &::before {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    width: 100%;
    border-bottom: 1px solid #333;
    content: '';
    position: absolute;
    top: -1px; 
    left: 0;
    right: 0;
    height: 0.5rem; 
    background-color: rgb(0 129 86);
  }
}
  
.modal-body-cbc {
  .item-group {
    padding: 8px;
    align-items: center;

    &:nth-child(odd) {
      background-color: #f8f9fa;
    }
  
    &:nth-child(even) {
      background-color: #e9ecef;
    }
  }
  
  .form-label {
    font-weight: 600;
  }
  
  .form-value {
    font-weight: normal;
    color: #212529;
  }
}
  

.cbc-del-btn {
  margin-right: 0.5rem; 
}

.cbc-save-btn {
  padding-right: 0.5rem; 
}