@use 'sass:color';
@import '../../../../styles/variables';

.timeline-toolbar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  width: 100%;
  gap: 10px;
  padding-left: 200px; 

  .btn {
    height: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: $primary;
    opacity: 1;

    &:hover {
      outline: none;
      background-color: color.adjust($primary, $lightness: -10%);
      color: white;
    }
  }

  .btn.active {
    background-color: $secondary;
    border-color: color.adjust($secondary, $lightness: -10%);
    opacity: 1;
    color: white;

    &:focus {
      color: white;
    }
  }

  .trash-icon-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    height:30px; /* Hoogte van de knop aanpassen aan je toolbar */
  }
  
  .trash-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dc3545; 
    border: none; /* Geen border voor de knop */
    border-radius: 4px; /* Standaard Bootstrap border-radius */
    padding: 6px;
    margin-right: 10px; /* Ruimte tussen knop en tekst */
  }
  
  .trash-icon {
    color: white; /* Witte icoon kleur */
    font-size: 1rem; /* Grootte van het icoon */
  }
  
  .trash-icon-text {
    white-space: nowrap;
    width: 200px;
    border-radius: 4px;
    background-color: transparent; /* Geen achtergrondkleur wanneer niet gehoverd */
    color: white; /* Witte tekst */
    transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
    padding: 6px 0.5rem; /* Verticale padding gelijk aan de knop */
    overflow: hidden;
    opacity: 0; /* Begin onzichtbaar */
    pointer-events: none; /* Zorg ervoor dat de tekst de hover-state niet beïnvloedt */
    transform: translateX(-100%);
  }
  
  .trash-icon-container:hover .trash-icon-text {
    background-color: #dc3545;
    opacity: 1;
    transform: translateX(0%); /* Terug naar normale positie */
    pointer-events: auto; /* Sta interactie toe wanneer zichtbaar */
  }
}
