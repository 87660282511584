@use 'sass:color';
@import '../../../../../styles/variables';

.windpark-select.in-modal {
  margin-left: 0;
}

.submit-btn {
  &:disabled {
    background-color: color.adjust(grey, $lightness: -30%);
    color: color.adjust($secondary, $lightness: -10%);
  }
}

.modal-header-manual {
  position: relative;
  padding-top: 1rem;
}

.modal-header-manual::before {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-color: $tertiary;
  border-bottom: 1px solid #333;
}

.modal-header-override::before{
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-color: $secondary;
  border-bottom: 1px solid #333;
}

.modal-header-external-lofar::before {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-color: $primary-light;
  border-bottom: 1px solid #333;
}

.modal-header-external-sweep::before {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-color: $primary;
  border-bottom: 1px solid #333;
}

.modal-header-duplicate {
  position: relative;
  padding-top: 1rem;

  &::before {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0.5rem;
    width: 100%;
    background-color: $warning; 
    border-bottom: 1px solid #333;
  }
}

.external-reference {
  display: grid;
  grid-template-rows: 0.5rem auto;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  margin: 0 0.75rem 0.75rem;

  .sweep {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    grid-row: 1;
    background-color: $primary;
    width: 100%;
  }

  .lofar {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    grid-row: 1;
    background-color: $primary-light !important;   
    width: 100%;
  }

  .external-reference-content {
    grid-row: 2;
    padding: 10px;

    h5 {
      margin-bottom: 10px;
    }

    .external-reference-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .detail-label {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
}