@import '../../../../styles/variables';

.wtg-types-table {
  margin-top: 1rem;

  thead {
    background-color: $primary;
    color: white;
    border-bottom: 4px solid $tertiary ;
  }

  .new-wtg-input {
    margin-left: 1rem;
    width: 30vw;

    .save-btn {
      position: absolute;
      margin-left: 0;
    }
  }

  .actions {
    .delete-btn {
      width: 100px;
      height: 40px;
    }
  }

  tbody {
    display: block;
    overflow-y: auto;
    height: 66vh;

    .transparent-row {
      td {
        width: 100%;
        height: 40px;
        align-items: center;
        background-color: rgb(250 250 250 / 50%);
      }

      &:nth-child(odd) {
        background-color: rgb(236 237 238 / 50%);
      }
    }
  }
}
