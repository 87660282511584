@use 'sass:color';
@import '../../styles/variables';

.dashboard-container {
  height: 100vh;
  background-image: url('../../assets/background.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .dashboard-content {
    width: 100%;
    background-color: rgb(255 255 255 / 80%);
    border-radius: 5px;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.api-url-display {
  background-color: red !important;
  color: white;
  width: 100%;
  text-align: center;
  height: 25px;
  font-weight: bold;

  .unbold {
    font-weight: lighter;
  }
}

.logo {
  width: 200px;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.button-container button {
  font-size: 1.5rem;
  padding: 1rem;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border: none;
}

.button-container button.btn-primary {
  &:hover,
  &:active {
    background-color: color.adjust($primary, $lightness: -10%);
    border-color: color.adjust($secondary, $lightness: -10%);
    color: #fff;
  }
}

.button-container button.btn-secondary {
  &:hover,
  &:active {
    background-color: color.adjust($secondary, $lightness: -10%);
    border-color: color.adjust($secondary, $lightness: -10%);
    color: #fff;
  }
}

.button-icon {
  font-size: 5rem;
  margin-bottom: 1rem;
}

.config-button {
  background-color: grey;

  &:hover,
  &:focus {
    background-color: grey !important;
  }
}
