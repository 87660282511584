@import '../../../../styles/variables';

.vis-panel.vis-top,
.vis-panel.vis-left {
  background-color: #fff;
}

.timeline {
  height: 100%;
}

.vis-foreground {
  overflow-y: visible;
}

.timeline-container {
  width:  100%;
  margin: 0 1rem;
  height: 100%;
  overflow-y: hidden;
  background-color: rgb(255 255 255 / 70%);
  position: relative;
}

.vis-selected {
  border: $secondary;
}

.context-menu-item {
  cursor: pointer !important;
}

.sweep { 
  background-color: #00008b !important;   
  color: white !important;

  &.active {
    background-color: #ADD8E6 !important;   
    color: white !important;
  }
  
  &.declined {
    background-color: #fc2a2a !important;
    color: white !important;
  }
}

.lofar { 
  background-color: $primary-light !important;   
  color: white !important; 
}

.override {
  background-color: $secondary !important;
  color: white !important;
}

.manual {
  background-color: $tertiary !important;
  color: white !important;
}

.cbc {
  color: white !important;
  background-color: rgb(0 129 86) !important;
}

.deleted {
  background-color: grey !important;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      rgb(0 0 0 / 70%) 10px, 
      rgb(0 0 0 / 70%) 20px
    );
    opacity: 0.3;
  }
}



.tooltip {
  pointer-events: none;
  background-color: rgb(50 50 50 / 80%);
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  display: none;
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.timeline-legend {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}

.zoom-controls {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 100;

  .zoom-button {
    border: none;
    background: none;
    font-size: 1.5rem;
    color: $secondary;
  }
}
