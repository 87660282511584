@import '../../../../../styles/variables';

.modal-header-external-lofar::before {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0.5rem;
    width: 100%;
    background-color: $primary-light;
    border-bottom: 1px solid #333;
}

.modal-header-external-sweep::before {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0.5rem;
    width: 100%;
    background-color: $primary;
    border-bottom: 1px solid #333;
}



.non-editable {
    background-color: #ddd;
    cursor: default;
  
    &:focus {
        border: none;
        background-color: #ddd !important;
    }
  
    .comment-field {
        background-color: #ddd;
  
        &:hover,
        &:focus {
            background-color: #ddd;
        }
    }
}

.item-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-bottom: 0;
}

.form-label {
    margin-right: 1rem;
}

.item-group > .form-label {
    text-align: left;
    font-weight: 600;
    margin-bottom: 0;
    flex-grow: 1;
}

.item-group > .form-text {
    text-align: right;
}

.item-group:nth-child(odd) {
    background-color: #f8f9fa;
}

.item-group:nth-child(even) {
    background-color: #e9ecef;
}
